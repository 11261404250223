.wpcf7-form p {
  margin-bottom: .75rem;
}

textarea,
[type='text'],
[type="search"],
[type="email"],
[type='password'] {
  border: 1px solid $border-grey;
  transition: all .1s ease;
  margin-top: .25rem;
  padding: .5rem;
  width: 100%;
  border-radius: 3px;
  &:hover {
    border-color: darken($border-grey, 10%);
  }
  &:active {
    border-color: lighten($border-grey, 10%);
  }
}

.wpcf7-submit {
}
