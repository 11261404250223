// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// global bottom margin
$global-margin: 2rem;

// MEDIA QUERIES

/// Breakpoints map
$breakpoints: (
  default: null,
  sm: 34em, //544px
  md: 48em, //768px
  lg: 62em, //992px
  xl: 75em  //1200px
) !default;


/// @type Length
$max-width: 1180px !default;


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
