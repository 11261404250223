// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
 .clearfix {
   &:after {
     content: "";
     display: table;
     clear: both;
   }
 }

/**
 * flat list
 */
.flat-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin: 0;
  }
}

/**
 * No wrap ellipsis
 */
 .nowrap {
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
 }

/**
 * nav list
 */

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  >li {
    display: block;
    margin-right: 1em;

    @include media-query(sm) {
      display: inline-block;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 */
.container {
//  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  width: 100%; /* 1 */
  flex: 1;
 }



/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
