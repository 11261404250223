/* =========================================================
Comments via https://gist.github.com/jeffgolenski/4391954
 ========================================================= */

 ol.comment-list {

     list-style: none;
     margin: 0 0 1rem;
     padding: 0;
     text-indent: 0;

 	li {}
 	li.alt {}
	li.bypostauthor {}
	li.byuser {}
	li.comment-author-admin {}


	li.comment {
		padding: 1rem;

		.comment-author {} // end .comment-author


		div.vcard {

			cite.fn {
				a.url {}
			 } // end cite.fn

			 img.avatar {
			 } // end .avatar

			 img.avatar-32 {} // end .avatar-32
			 img.photo {} // end .photo
			 span.says {} // ebd .says

		}  // end .vcard

		div.commentmetadata {} // end .commentmetadata
		div.comment-meta {


				a {
				} // end a

		} // end div.comment-meta

		p {} // end p

		ul {
			list-style: none;
		} // end ul

		div.reply {


			a {

			} // end a

		} // end reply

		 .children {
			list-style:none;

			li {} // end li

			li.alt {}
			li.bypostauthor {}
			li.byuser {}
			li.comment {}
			li.comment-author-admin {}

			li.depth-2 { border-left: 1px solid $border-grey; margin: 0 0 .5rem 1rem; }
			li.depth-3 { border-left: 1px solid $border-grey; margin: 0 0 .5rem 1rem; }
			li.depth-4 { border-left: 1px solid $border-grey; margin: 0 0 .5rem 1rem; }
			li.depth-5 {} // you get the idea

			li.odd {} // end .odd

		}  // end ul.children

	}  // end li.comment

	li.even {}
	li.odd  {background: darken($background-grey, 2%);}
	li.thread-alt {}
	li.thread-even {}
	li.thread-odd {}

 } // end commentlist


/* =========================================================
Comments reply box
========================================================= */

.comment-form-comment label {
  display: block;
}
.comment-form-comment textarea {
  width: 100%;
}

.form-submit {
  text-align: right;
}
