// -----------------------------------------------------------------------------
// This file contains all styles related to the navigation of the site/application.
// -----------------------------------------------------------------------------

// main nav

.main-nav {
  width: 100%;
  display: none;
  padding-top: 2rem;
  text-align: right;

  @include media-query(lg) {
    display: block;
    padding: 0;
    width: 100%;
  }
}

.nav-opener {
  display: block;
  width:  24px;
  height: 24px;
  cursor: pointer;
  margin-right: 1rem;
  background-color: transparent;
  border: none;
  appearance: none;
  background-image: url('../img/burger.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: .5;

  &:hover {
    opacity: .75;
  }
  &:focus,
  &:active {
    outline: none;
    opacity: 1;
  }

  @include media-query(lg) {
    display: none;
  }
}
.nav-opener:checked {
  background-image: url('../img/x.svg');
}

.nav-opener:checked ~ .main-nav  {
  display: block;
}

// main nav list

.main-nav .menu ul {
  width: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  @include media-query(lg) {
    flex-direction: row;
    width: auto;
  }

   li {
    width: 100%;
    @include media-query(lg) {
      width: auto;
    }
      a {
       display: block;
       width: 100%;
       padding: 1rem;
       cursor: pointer;
       font-family: $font-family-serif;
       font-size: 125%;
       color: $text-color;

        @include media-query(lg) {
          padding: 1rem;
        }

       &:hover {
         text-decoration: underline;
       }
      }
  }

 .current-menu-item > a,
 .current_page_parent > a {
    font-weight: $font-weight-bold;
  }


  // Submenu
  /* dropdown */

  .sub-menu {
    display: block;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    background-color: $background-grey;

      @include media-query(lg) {
        position: absolute;
        display: none;
      }
    }

    li:hover .sub-menu {
      display: block;

      @include media-query(lg) {
        box-shadow: 0 .25rem 1rem rgba(0,0,0,.1);
      }
    }
    .sub-menu a {
      display: block;
      min-width: 10rem;
      padding-left: 2rem;

      @include media-query(lg) {
        padding-left: 1rem;
      }
    }

}


// Footer navigation

.post-navigation {
  margin-bottom: calc(#{$global-margin} * 2);
}


// footer nav

.footer-nav .menu {
  @extend .nav-list;
}
