/**
 * Basic typography style for copy text
*/

// Weights
$font-weight-light: 300;
$font-weight-regular:  400;
$font-weight-bold: 600;

// Base font size
$base-font-size: 1.125rem;

// Base font size
$base-line-height: 1.5;

// Sizes
// @include font-size-small .75rem;
// @include font-size-regular 1rem;
// @include font-size-large 1.25rem;
// @include font-size-x-large 1.75rem;
// @include font-size-xx-large 2rem;
// @include font-size-xxx-large 2.5rem;
// @include font-size-xxxx-large 3rem;



// Families

$font-family-text: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Ubuntu, Oxygen-Sans, Cantarell, sans-serif !default;

$font-family-serif:  Athelas, Georgia, serif !default;

$font-family-heading: $font-family-serif;

$font-family-code: 'Consolas', 'Menlo', 'Inconsolata', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;
