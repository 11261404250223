.site-main {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  display: flex;
  flex-direction: column;
  @extend .clearfix;
}

@include media-query('lg'){
  .site-main {
    flex-direction: row;
  }
}

.col {
  flex: 1;
  width: 100%;
  padding: 1rem 2rem;
}

@include media-query('lg'){
  .col {
    width: 50%;
    padding: 2rem;
    margin: .5rem;
    background-color: rgba($background-color, .5);
  }
}

.slideshow img {
  max-height: 500px;
  margin: auto;
}
