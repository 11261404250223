.slide {
  display: none;
  outline: none;
  width: 100%;
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:focus {
    outline: none;
  }

  @include media-query('lg'){
    min-height: 500px;
    background-size: cover;
  }
}


.slide-1 {background-image: url('../img/slide-500px-kitchen-table-blur.jpg');}
.slide-2 {background-image: url('../img/slide-500px-chairs.jpg');}
.slide-3 {background-image: url('../img/slide-500px-two-chairs.png');}
.slide-4 {background-image: url('../img/slide-500px-small-tables.png');}
.slide-5 {background-image: url('../img/slide-500px-cabinet-closed.png');}
.slide-6 {background-image: url('../img/slide-500px-office.jpg');}
.slide-7 {background-image: url('../img/slide-500px-feathers.png');}
.slide-8 {background-image: url('../img/slide-500px-dresser-clipped.png');}
