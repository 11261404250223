// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

@mixin button-bg($brand-color) {
  background: $brand-color;
  &:hover {
    background:darken($brand-color,8%);
  }
  &:active {
    background:darken($brand-color,25%);
  }
}


.btn,
button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: $brand-color;
  color: white;
  transition: all .1s ease;
  border: none;
  padding: .5rem 1.5rem;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: darken($brand-color, 10%);
  }
  &:active {
    background-color: lighten($brand-color, 10%);
  }
}
