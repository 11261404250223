
// COLORS

$background-grey: #fafafa;
$border-grey: #ddd;
$dark-grey: #333;

$brand-color: #8d5433;

/// Background color
$background-color: #F4F1D9 !default;

/// Heading text color
$heading-text-color: darken($dark-grey,10%)!default;

/// Copy text color
$text-color: $dark-grey !default;


// social colors

$social-email:       #4e9bac;
$social-github:      #4183c4;
$social-twitter:     #00aced;
$social-facebook:    #3b5998;
$social-googleplus:  #dd4b39;
$social-pinterest:   #cb2027;
$social-linkedin:    #007bb6;
$social-youtube:     #bb0000;
$social-vimeo:       #aad450;
$social-tumblr:      #32506d;
$social-instagram:   #517fa4;
$social-flickr:      #ff0084;
$social-dribbble:    #ea4c89;
$social-quora:       #a82400;
$social-foursquare:  #0072b1;
$social-forrst:      #5B9A68;
$social-vk:          #45668e;
$social-wordpress:   #21759b;
$social-stumbleupon: #EB4823;
$social-yahoo:       #7B0099;
$social-blogger:     #fb8f3d;
$social-soundcloud:  #ff3a00;
