// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


.main-header {
  display: flex;
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  // margin-bottom: $global-margin;

  @include media-query(lg) {
    flex-wrap: nowrap;
    // margin-bottom: calc(#{$global-margin} * 3);
  }

  .main-header__site-title {
    flex: 1;
  }
}

.site-title {
  font-size: 100%;
  margin: 0;
  padding: 0;

  @include media-query(lg) {
    width: auto;
  }
}

.site-title--logo {
  font-size: 0;
  color: transparent;
  line-height: 1;
  margin-left: 1rem;
  a {
    display: block;
    width: 220px;
    height: 40px;
    background-image: url('../img/sheepshape-logo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    transition: all .1s cubic-bezier(.17,.67,.83,.67);
      &:hover {
      }
      &:active {
      }
      &:focus {
      }
  }
      &:after {
        content: none;
      }
}
