// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  background-color: $background-color;
  height: 100%;
  color: $text-color;
}

// for sticky footer
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  border: 0;
  font-size: $base-font-size;
  font-family: $font-family-serif;
  line-height: $base-line-height;
  position: relative;
  background-image: url('../img/bg-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  @include media-query(md) {
    // border: $global-margin solid $border-grey;
  }

}

/**
 * Bottom margins only!
 */

h1, h2, h3, h4, h5, h6,
ul, ol, dd,
p, figure,
pre, table, fieldset, hr {
  margin: auto auto $global-margin;
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  line-height: 1.1;
  color: $heading-text-color;
  margin: 0 auto calc(#{$global-margin} / 1.5);
  word-wrap: break-word;
}

h1 {font-size: font-size-xxxx-large;}
h2 {font-size: 2rem;}
h3 {font-size: 1.75rem;}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  transition: all .1s ease;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}


/**
 * Addresses
 */

address {
  font-style: normal;
}
