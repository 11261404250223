// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


.main-footer {
  padding: 2rem 1rem;
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column-reverse;

  @include media-query('lg'){
    flex-direction: row;
  }

  .colophon  {
    margin-bottom: 2rem;

    @include media-query('md'){
      margin-bottom: .5rem;
    }
  }

  .footer-col {
    width: 100%;
    flex: 1;

    @include media-query('lg'){
        width: 50%;
    }
  }

  .footer-logo {

  .dgoc-logo {
    width: 75px;
  }

      @include media-query('lg'){
        text-align: right;
      }
  }

}
